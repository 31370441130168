#toast-container .toast{
    color: #262626;
}
.m-processing {
    opacity: 0.5;
}
.m-margin-v-10{
    margin:10px 0;
}
header.navbar .navbar-brand {
    position: relative;
    background-image: url("../images/logo_cms_h.png");
    background-size: 225px auto;
}
header.navbar .navbar-brand.is{
    background-image: url("../images/logo_is_h.png");
}
header.navbar .navbar-brand.admin{
    background-image: url("../images/logo_admin_h.png");
}
header.navbar .navbar-brand-version{
    position: absolute;
    top:40px;
    right:15px;
    background-color: #f86c6b;
    border-radius: 5px;
    font-size: 8pt;
    padding-right: 0.2em;
    padding-left: 0.2em;
    display: inline-block;
    color: #fff;
}
.m-align-left{
    text-align: left;
}
td.details-control {
    background: url('../images/expand.png') no-repeat center center;
    cursor: pointer;
}
tr.shown td.details-control {
    background: url('../images/collapse.png') no-repeat center center;
}
.margin-right-10{
    margin-right: 10px;
}
.sidebar-treeview .list-group-item{
    padding: 0.25rem 0;
}
.sidebar-treeview span.icon{
    margin:0 0 0 10px;
}
.sidebar-treeview span.node-icon{
    margin:0px !important;
    width:5px !important;;
}
.aside-menu-fixed .aside-menu{
    flex: 0 0 500px;
    width: 500px;
}
.aside-menu-hidden .aside-menu {
    margin-right: -500px;
}

.aside-menu-fixed .main, .aside-menu-fixed .app-footer {
    margin-right: 500px;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect{
    padding: 1px 5px;
}
.adminTopBar a{
    color: #20a8d8 !important;
}

.adminTopBar a.active{
    color: rgba(0, 0, 0, 0.8) !important;
}

.dropzone {
    background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0.25, rgba(0, 0, 0, .03)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(0, 0, 0, .03)), color-stop(0.75, rgba(0, 0, 0, .03)), color-stop(0.75, transparent), to(transparent)) !important;
    background-image: -webkit-linear-gradient(135deg, rgba(0, 0, 0, .03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .03) 50%, rgba(0, 0, 0, .03) 75%, transparent 75%, transparent)!important;
    background-image: -moz-linear-gradient(135deg, rgba(0, 0, 0, .03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .03) 50%, rgba(0, 0, 0, .03) 75%, transparent 75%, transparent)!important;
    background-image: -ms-linear-gradient(135deg, rgba(0, 0, 0, .03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .03) 50%, rgba(0, 0, 0, .03) 75%, transparent 75%, transparent)!important;
    background-image: -o-linear-gradient(135deg, rgba(0, 0, 0, .03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .03) 50%, rgba(0, 0, 0, .03) 75%, transparent 75%, transparent)!important;
    background-image: linear-gradient(135deg, rgba(0, 0, 0, .03) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, .03) 50%, rgba(0, 0, 0, .03) 75%, transparent 75%, transparent)!important;
    background-color: #FAFCFD!important;
    background-size: 16px 16px!important;
    width: 100%!important;
    height: 300px!important;
}

.dropzone .dz-default.dz-message {
    background-size: 100%;
    width: 100%;
    height: 43px;
    margin-top: 20px;
    text-align: center;
    font-size: 18pt;
    font-style: italic;
    font-weight: bold;
}

.b-gallery-selected {
    border-left: 1px solid #d1d4d7;
    border-radius: 0.25rem;
}

.b-gallery-file {
    padding-right: 5px;
    padding-left: 5px;
    width: 150px;
    max-width: 150px;
    flex: 0 0 150px;
}

.b-gallery-file:hover .b-gallery-file-buttons,
.b-gallery-file.selected .b-gallery-file-buttons {
    display: block;
}

.b-gallery-file:hover .card-block,
.b-gallery-file.selected .card-block {
    background: #cccccc;
}

.b-gallery-file:hover .card-block img,
.b-gallery-file.selected .card-block img {
    opacity: 0.5;
}

.b-gallery-file-preview {
    width: 100%;
    height: auto;
}
.b-gallery-file-buttons {
    display: none;
    position: absolute;
    top: 80px;
    left: 50%;
    width: 100px;
    height: 27px;
    margin: -25px 0 0 -50px;
}

#fbplus-loading div {
    background-image: url('/images/fbplus/fbplus.png') !important;
}

#fbplus-close {
    background: transparent url('/images/fbplus/fbplus.png') -40px 0px !important;
}

#fbplus-left, #fbplus-right {
    background: transparent url('/images/fbplus/blank.gif') !important;
}

#fbplus-left-ico {
    background-image: url('/images/fbplus/fbplus.png') !important;
}

#fbplus-right-ico {
    background-image: url('/images/fbplus/fbplus.png') !important;
}

#fbplus-bg-n {
    background-image: url('/images/fbplus/fbplus_shadow_n.png') !important;
}

#fbplus-bg-ne {
    background-image: url('/images/fbplus/fbplus_shadow_ne.png') !important;
}

#fbplus-bg-e {
    background-image: url('/images/fbplus/fbplus_shadow_e.png') !important;
}

#fbplus-bg-se {
    background-image: url('/images/fbplus/fbplus_shadow_se.png') !important;
}

#fbplus-bg-s {
    background-image: url('/images/fbplus/fbplus_shadow_s.png') !important;
}

#fbplus-bg-sw {
    background-image: url('/images/fbplus/fbplus_shadow_sw.png') !important;
}

#fbplus-bg-w {
    background-image: url('/images/fbplus/fbplus_shadow_w.png') !important;
}

#fbplus-bg-nw {
    background-image: url('/images/fbplus/fbplus_shadow_nw.png') !important;
}
#fbplus-title-over {
    background-image: url('/images/fbplus/fbplus_title_over.png') !important;
}
#fbplus-title-float-left {
    background: url('/images/fbplus/fbplus.png') -40px -90px no-repeat !important;
}

#fbplus-title-float-main {
    background: url('/images/fbplus/fbplus-x.png') 0px -40px !important;
}

#fbplus-title-float-right {
    background: url('/images/fbplus/fbplus.png') -55px -90px no-repeat !important;
}
/* IE6 */

.fbplus-ie6 #fbplus-close {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_close.png', sizingMethod='scale') !important;
}

.fbplus-ie6 #fbplus-left-ico {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_nav_left.png', sizingMethod='scale') !important;
}

.fbplus-ie6 #fbplus-right-ico {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_nav_right.png', sizingMethod='scale') !important;
}

.fbplus-ie6 #fbplus-title-over {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_title_over.png', sizingMethod='scale') !important;
}

.fbplus-ie6 #fbplus-title-float-left {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_title_left.png', sizingMethod='scale') !important;
}

.fbplus-ie6 #fbplus-title-float-main {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_title_main.png', sizingMethod='scale') !important;
}

.fbplus-ie6 #fbplus-title-float-right {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_title_right.png', sizingMethod='scale') !important;
}

#fbplus-loading.fbplus-ie6 div {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_loading.png', sizingMethod='scale') !important;
}

/* IE6, IE7, IE8 */

.fbplus-ie #fbplus-bg-n {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_n.png', sizingMethod='scale') !important;
}

.fbplus-ie #fbplus-bg-ne {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_ne.png', sizingMethod='scale') !important;
}

.fbplus-ie #fbplus-bg-e {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_e.png', sizingMethod='scale') !important;
}

.fbplus-ie #fbplus-bg-se {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_se.png', sizingMethod='scale') !important;
}

.fbplus-ie #fbplus-bg-s {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_s.png', sizingMethod='scale') !important;
}

.fbplus-ie #fbplus-bg-sw {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_sw.png', sizingMethod='scale') !important;
}

.fbplus-ie #fbplus-bg-w {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_w.png', sizingMethod='scale') !important;
}

.fbplus-ie #fbplus-bg-nw {
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='/images/fbplus/fbplus_shadow_nw.png', sizingMethod='scale') !important;
}
#fbplus-close{
    top: -10px !important;
    right: -30px !important;;
}

.ql-editor{
    min-height: 165px !important;
}

.ql-container {
    height: auto !important;
}

@media only screen and (max-width: 991px) {
    .main .container-fluid.container-fluid-responsive {
        padding:0px !important;
    }

    .main .container-fluid.container-fluid-responsive .email-app nav{
        flex: 0 0 50px;
    }

    .main .container-fluid.container-fluid-responsive .email-app nav ul.nav li.nav-item a.nav-link, .email-app nav ul.nav li.nav-item .navbar a.dropdown-toggle, .navbar .email-app nav ul.nav li.nav-item a.dropdown-toggle{
        padding: 0px !important;
        text-align: center;
    }

    .main .container-fluid.container-fluid-responsive .breadcrumb{
        padding: 0.75rem 0rem;
    }

    .main .container-fluid.container-fluid-responsive .email-app main ul.messages-list li .header .date{
        width: 100%;
        float: none;
    }
    .main .container-fluid.container-fluid-responsive .email-app main ul.messages-list li .description{
        padding-left: 0px;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}
@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}
.m-padding-0{
    padding: 0px !important;
}